import Image from "next/image";
import Link from 'next/link'

export default function ProductContent({ products }) {
  return (
    <section className="relative bg-gray-50 py-12">
      <h4 className="text-2xl lg:text-4xl text-gray-900 mb-8 font-serif font-medium text-center font-medium">
        Popular Skincare Products
      </h4>
      <div className="mt-12 max-w-2xl mx-auto grid gap-8 sm:grid-cols-3 px-6 lg:grid-cols-3 md:max-w-4xl lg:max-w-7xl lg:mx-auto lg:px-8">
        {products.map((product) => {
          const image = product.media.find(x => x.asset.type.value === 'IMAGE')
          return (
            <div
              className="bg-white relative flex flex-col rounded-lg shadow-lg overflow-hidden"
              key={product.id}
            >
              <Link href={product.metadata.path} legacyBehavior>
                <>
                  <div className="relative h-48 flex-shrink-0">
                    <Image
                      src={image.asset.url}
                      className="h-48 w-full object-contain"
                      alt={product.title}
                      fill
                      sizes="100vw" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="font-medium text-brand-600">
                        Skincare
                      </p>
                      <div className="block mt-2">
                        <p className="text-xl lg:text-3xl font-serif font-medium text-gray-900">
                          {product.title}
                        </p>
                      </div>
                    </div>
                  </div>
                  <a href={product.metadata.path} className="stretch-link"><span className='sr-only'>{product.metadata.description} - {product.title} available at Charmelle London</span></a>
                </>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
}
